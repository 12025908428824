import { ADD_CHARACTER, DELETE_CHARACTER } from "../actions";

const characterReducer = (state = [], action) => {
  switch (action.type) {
    case ADD_CHARACTER:
      const {
        id,
        name,
        height,
        mass,
        hair_color,
        skin_color,
        eye_color,
        birth_year,
        gender,
        homeworld,
        films,
        filmData,
        species,
        vehicles,
        starships,
        created,
        edited,
        url,
      } = action.payload;
      return [
        ...state,
        {
          id,
          name,
          height,
          mass,
          hair_color,
          skin_color,
          eye_color,
          birth_year,
          gender,
          homeworld,
          films,
          filmData,
          species,
          vehicles,
          starships,
          created,
          edited,
          url,
        },
      ];

    case DELETE_CHARACTER:
      const { index } = action.payload;
      // console.log(index)
      return state.filter((character, i) => i !== index);
    default:
      return state;
  }
};

export default characterReducer;
