import {
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  useToast,
  Button,
  Flex,
} from "@chakra-ui/react";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addCharacter } from "../actions";
import Character from "./character";
const StarWars = () => {
  const [num, setNumber] = useState(1);
  const toast = useToast();
  const characters = useSelector((state) => state.characters);
  const [character, setCharacter] = useState(null);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const handleChange = (val) => {
    let newVal = parseInt(val);

    if (newVal !== 17) {
      setNumber(val);
    } else {
      setNumber(1);
      toast({
        title: "Please enter any number besides 17",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
    }
  };

  useEffect(() => {
    // console.log(character);
  }, [character]);

  const handleSubmit = async () => {
    setLoading(true);
    setCharacter(null)
    if (num !== "") {
      let exists = characters.find((character) => character.id === num);
      if (exists) {
        setCharacter(exists);
        setLoading(false)
      } else {
        await axios
          .get("https://swapi.dev/api/people/" + num)
          .then(async (res) => {
            // setCharacter({ ...res.data, id: num, filmData: [] });
            if (res.data.homeworld !== "") {
              await axios.get(res.data.homeworld).then((res2) => {
                setCharacter({
                  ...res.data,
                  id: num,
                  homeworld: res2.data,
                  filmData: [],
                });
              });
            } else {
              setCharacter({
                ...res.data,
                homeworld: {},
                id: num,
                filmData: [],
              });
            }
            const filmsRes = await Promise.all(res.data.films.map(film => fetch(film)))
            const filmsJSON = await Promise.all(filmsRes.map(res => res.json()))
            setCharacter(prevState => ({...prevState, filmData: filmsJSON}))
            setLoading(false)
          });
      }
    } else {
      toast({
        title: "Please enter a number",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
    }
  };

  const handleSave = () => {
    let exists = characters.find((character) => character.id === num);
    if (exists) {
      toast({
        title: "Character already saved",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
    } else {
      dispatch(
        addCharacter(
          num,
          character.name,
          character.height,
          character.mass,
          character.hair_color,
          character.skin_color,
          character.eye_color,
          character.birth_year,
          character.gender,
          character.homeworld,
          character.films,
          character.filmData,
          character.species,
          character.vehicles,
          character.starships,
          character.created,
          character.edited,
          character.url
        )
      );
      toast({
        title: "The character has been saved",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "bottom",
      });
    }
  };
  return (
    <>
      <Flex mt={5} flexDir={"column"}>
        <Flex>
          <NumberInput
            borderColor={"blue.600"}
            onChange={(val) => handleChange(val)}
            defaultValue={num}
            value={num}
            min={1}
            max={83}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
          <Button
            _hover={{ bg: "blue.700" }}
            onClick={handleSubmit}
            color="white"
            bg={"blue.600"}
            ml={3}
            isLoading={loading}
          >
            Submit
          </Button>
          <Button
            disabled={!character || loading}
            _hover={{ bg: "red.700" }}
            onClick={handleSave}
            color="white"
            bg={"red.600"}
            ml={3}
          >
            Save
          </Button>
        </Flex>
        {character && <Character character={character} />}
      </Flex>
    </>
  );
};

export default StarWars;
