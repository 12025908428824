import { Heading, Stack, Text } from "@chakra-ui/react"

const Home = () => {
  return (
    <>
    <Stack>
      <Heading>Elias Wambugu</Heading>
      <Text fontSize={20} fontWeight={200}>ACS 3330</Text>
    </Stack>
    </>
  )
}

export default Home