export const ADD_CHARACTER = "ADD_CHARACTER";
export const DELETE_CHARACTER = "DELETE_CHARACTER";

export const addCharacter = (
  id,
  name,
  height,
  mass,
  hair_color,
  skin_color,
  eye_color,
  birth_year,
  gender,
  homeworld,
  films,
  filmData,
  species,
  vehicles,
  starships,
  created,
  edited,
  url,
) => {
  return {
    type: ADD_CHARACTER,
    payload: {
      id,
      name,
      height,
      mass,
      hair_color,
      skin_color,
      eye_color,
      birth_year,
      gender,
      homeworld,
      films,
      filmData,
      species,
      vehicles,
      starships,
      created,
      edited,
      url,
    },
  };
};

export const deleteCharacter = (index) => {
  return {
    type: DELETE_CHARACTER,
    payload: {index},
  }
}