import { Flex, Image } from "@chakra-ui/react";
import Home from "./components/home";
import Saved from "./components/saved";
import StarWars from "./components/starwars";

const App = () => {
  return (
    <>
      <Image pos='absolute' zIndex={-1} h='100vh' w='100%' src={require("./assets/images/background.jpeg")} />
      <Flex
        justifyContent="center"
        p={20}
        flexDir="column"
        w="100%"
        h="100vh"
      >
        <Flex
          rounded={10}
          p={5}
          bg="white"
          alignSelf={"center"}
          flexDir={"column"}
        >
          <Home />
          <StarWars />
        </Flex>
        <Saved />
      </Flex>
    </>
  );
};

export default App;
