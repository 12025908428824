import { DeleteIcon } from "@chakra-ui/icons";
import {
  Flex,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Heading,
  IconButton,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Stack,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { deleteCharacter } from "../actions";

const Saved = () => {
  const characters = useSelector((state) => state.characters);

  return (
    <>
      <Flex
        bg="white"
        p={4}
        rounded={10}
        w={{ base: 200, sm: 350 }}
        flexDir={"column"}
        mt={10}
        alignSelf={"center"}
      >
        <Heading color="gray.600">Saved Characters</Heading>
        <TableContainer mt={3}>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>id</Th>
                <Th>Name</Th>
                <Th>Delete</Th>
              </Tr>
            </Thead>
            <Tbody>
              {characters.map((character, index) => (
                <CharacterModal
                  key={index}
                  index={index}
                  character={character}
                />
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Flex>
    </>
  );
};

const CharacterModal = ({ character, index }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const dispatch = useDispatch();

  return (
    <>
      <Tr>
        <Td>{character.id}</Td>
        <Td onClick={onOpen} _hover={{ color: "blue.400", cursor: "pointer" }}>
          {character.name}
        </Td>
        <Td>
          <IconButton
            onClick={() => dispatch(deleteCharacter(index))}
            colorScheme={"red"}
            icon={<DeleteIcon />}
          />
        </Td>
      </Tr>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{character.name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex>
              <Text fontWeight={600}>Homeworld: </Text>
              <Text ml={1}>{character.homeworld.name}</Text>
            </Flex>
            <Flex>
              <Text fontWeight={600}>Height: </Text>
              <Text ml={1}>{character.height}</Text>
            </Flex>
            <Flex>
              <Text fontWeight={600}>Hair Color: </Text>
              <Text ml={1}>{character.hair_color}</Text>
            </Flex>
            <Flex>
              <Text fontWeight={600}>Films: </Text>
              <Stack ml={1}>
                {character.filmData &&
                  character.filmData.map((film, index) => (
                    <Text key={index} ml={1}>
                      {index + 1}. {film.title}
                    </Text>
                  ))}
              </Stack>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Saved;
