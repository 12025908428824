import { Flex, Stack, Text } from "@chakra-ui/react";

const Character = ({ character }) => {
  const { name, height, homeworld, hair_color, filmData } = character;
 
  return (
    <>
      <Flex flexDir={"column"} mt={2}>
        <Text fontSize={20} fontWeight={600}>
          Results
        </Text>
        <Flex>
          <Text fontWeight={600}>Name:</Text>
          <Text ml={2}>{name}</Text>
        </Flex>
        <Flex>
          <Text fontWeight={600}>Homeworld:</Text>
          <Text ml={2}>{homeworld.name}</Text>
        </Flex>
        <Flex>
          <Text fontWeight={600}>Height:</Text>
          <Text ml={2}>{height}</Text>
        </Flex>
        <Flex>
          <Text fontWeight={600}>Hair Color:</Text>
          <Text ml={2}>{hair_color}</Text>
        </Flex>
        <Flex>
          <Text fontWeight={600}>Films:</Text>
          <Stack ml={2}>
            {filmData &&
              filmData.map((film, index) => (
                <Text key={index} >
                  {index+1}. {film.title}
                </Text>
              ))}
          </Stack>
        </Flex>
      </Flex>
    </>
  );
};

export default Character;
